import clsx from 'clsx'
import Link from 'next/link'
import type { HTMLAttributes } from 'react'
import React, { useEffect, useState } from 'react'
import routes from '../../../lib/routes'
import type { BigCommerceGQLCategoryTree3LevelsDeep } from '../../../lib/serverOnly/api/bigcommerce/graphql/catalog'
import type { Brand as BigCommerceBrand } from '../../../lib/serverOnly/api/bigcommerce/v3/brand'
import LegacyContainer from '../legacy/container/LegacyContainer'
import { LegacyChevronDownIcon } from '../legacy/icons/LegacyChevronDownIcon'
import DynamicHeaderNavValueProp from './DynamicHeaderNavValueProp'
import { ShopByMenu } from './ShopByMenu'

export type DesktopSubheaderProps = {
  categories: BigCommerceGQLCategoryTree3LevelsDeep
  featuredBrands: Array<BigCommerceBrand>
  asPath: string
  displayPhoneOutageAlert: boolean
  onCloseSearchMenu: () => void
}

function ListItem({ className, children, ...rest }: HTMLAttributes<HTMLLIElement>) {
  return (
    <li className={clsx(className, 'inline-flex flex-row items-center px-4 py-2')} {...rest}>
      {children}
    </li>
  )
}

export const DesktopSubheader = React.forwardRef<HTMLElement, DesktopSubheaderProps>(
  ({ categories, featuredBrands, asPath, displayPhoneOutageAlert, onCloseSearchMenu }, ref) => {
    const [hoveringOverButton, setHoveringOverButton] = useState(false)
    const [hoveringOverMenu, setHoveringOverMenu] = useState(false)
    const [activeMenuItem, setActiveMenuItem] = useState(categories[0])

    useEffect(() => {
      setHoveringOverMenu(false)
    }, [asPath])

    const setHoverAndCloseSearchMenu = () => {
      setHoveringOverButton(true)
      onCloseSearchMenu()
    }

    return (
      <nav
        ref={ref}
        className="hidden bg-white text-xl print:hidden lg:block border-b border-[#E2E1E5]"
      >
        <LegacyContainer className="relative flex flex-row ">
          <ul className="flex-1 text-left">
            <ListItem
              tabIndex={0}
              className={clsx(
                'cursor-pointer font-bold focus:outline-none',
                hoveringOverButton ? 'bg-ds-neutral text-ds-neutral-900' : 'bg-white text-coolGray'
              )}
              onFocus={() => {
                setHoveringOverButton(true)
              }}
              onBlur={() => {
                setHoveringOverButton(false)
              }}
              onMouseEnter={() => {
                setHoverAndCloseSearchMenu()
              }}
              onMouseLeave={() => {
                setHoveringOverButton(false)
              }}
              onTouchStart={() => {
                setHoverAndCloseSearchMenu()
              }}
              role="menuitem"
              aria-haspopup={true}
              aria-controls="shop-by-menu"
            >
              <span>SHOP BY</span>
              <LegacyChevronDownIcon className="ml-2 h-4 w-4" />
            </ListItem>

            <ListItem>
              <Link href={routes.featuredBrands()} prefetch={false} className="text-coolGray">
                Brands
              </Link>
            </ListItem>

            <ListItem>
              <Link href={routes.deals()} prefetch={false} className="text-coolGray">
                Deals
              </Link>
            </ListItem>

            <ListItem>
              <Link href={routes.resources()} prefetch={false} className="text-coolGray">
                Learn
              </Link>
            </ListItem>
          </ul>

          <div
            className="flex-none shrink grow py-2 pr-4 text-right text-xl font-normal leading-7 text-coolGray xl:pr-0"
            style={{
              // TODO: Once upgraded to tailwind v3 we can use "basis-0"
              flexBasis: 0,
            }}
          >
            <DynamicHeaderNavValueProp displayPhoneOutageAlert={displayPhoneOutageAlert} />
          </div>

          {(hoveringOverButton || hoveringOverMenu) && (
            <div
              onMouseEnter={() => {
                setHoveringOverMenu(true)
              }}
              onMouseLeave={() => {
                setHoveringOverMenu(false)
              }}
              className="absolute left-0 top-full z-20 mx-4 w-full bg-ds-neutral text-left text-ds-neutral-900 shadow-popup"
            >
              <ShopByMenu
                activeMenuItem={activeMenuItem}
                onChangeActiveMenuItem={setActiveMenuItem}
                categories={categories}
                featuredBrands={featuredBrands}
              />
            </div>
          )}
        </LegacyContainer>
      </nav>
    )
  }
)
